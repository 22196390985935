// import { Auth } from 'aws-amplify';
// var a = 'ddd'
export function validateNotEmpty(v) {
  if (v === null || (v != undefined && v.match(/^ *$/) !== null) || v === undefined) {
    return 'Field cannot be empty'
  }
  return true
}
export function validateNotTooLong(v) {
  if (v === null || (v != undefined && v.match(/^ *$/) !== null)) {
    return 'Field cannot be empty'
  }
  else if (v != undefined && v.length > 200) {
    return 'Enter less than 200 characters'
  }
  return true
}
export function validatePhoneNumber(v) {
  if (!/^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(v)) {
    if (v == null) {
      return true
    }
    return 'Enter a valid phone number'
  }
  return true
}
export function validateIP(v) {
  if (!/^\d+$/.test(v) && (v != '' || v != null) && v != undefined) {
    if (!/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(v)) {
      return 'Enter valid ip'
    }
    return true
  }
  return true
}
export function validateCountry(v) {
  if (v != 'Canada' && v != 'United States') {
    return 'Sorry, BETSY is currently unavailable in your country'
  }
  return true
}
export function validateEmail(v) {
  if (
    ! /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
      String(v).toLowerCase()
    )
  ) {
    return 'Invalid email format'
  }
  return true
}
export function validateEmailRegister(v) {
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(v).toLowerCase()
    )
  ) {
    return 'Invalid email format'
  }
  return true
}

export function validatePassword(v) {
  if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/.test(
      v
    )
  ) {
    return 'Password must be 8 characters long and contain at least one upper case character, number and a special character'
  }
  return true
}
export function validateSamePassword(passwordOne, passwordTwo) {
  if (!(passwordOne === passwordTwo)) {
    return 'Passwords must match'
  }
  return true
}

export function validateIsNumber(v) {
  if (!/^\d+$/.test(v)) {
    if (v === null || (v != undefined && v.match(/^ *$/) !== null) || v == "" || v == undefined) {
      return true
    }
    return 'Must be a number'
  }
  return true
}

export function validateIsFloat(v) {
  if (!/^\d+$/.test(v) && (v != '' || v != null)) {
    if ((v != undefined && /^[+-]?\d+(\.\d+)?$/.test(v)) || (v != undefined && v != null && v.length == 0) || v == undefined) {
      return true
    }
    return 'Must be a number'
  }
  return true
}

export function validateDate(v) {
  if (!/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(v)) {
    return 'Enter a valid date (YYYY-MM-DD)'
  }
  return true
}
export function validateDateEmpty(v) {
  if (v == '' || v == null || v == undefined) {
    return true
  }
  if (!/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(v)) {
    return 'Enter a valid date (YYYY-MM-DD)'
  }
  return true
}

export function validateDelete(v) {
  if (!(v === 'Confirm Delete')) return 'Incorrect phrase'
  return true
}

export default {
  validateIsFloat,
  validateNotEmpty,
  validateEmail,
  validatePassword,
  validateSamePassword,
  validatePhoneNumber,
  validateIsNumber,
  validateDelete,
  validateDate
}