/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:6a083b1b-73d2-4b1e-add0-f851bb89c046",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_LX5t2Uari",
    "aws_user_pools_web_client_id": "5boo203cubtllut3r5atvqnniu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://2uaqtbmamvd4fp3ael3oqsduca.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2", 
    "aws_appsync_authenticationType": "AWS_LAMBDA"
};


export default awsmobile;