import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import argus_go_icon from '@/components/argus_go_icon.vue'
import r511w from '@/components/r511w.vue'
import reolink_go from '@/components/reolink_go.vue'
import rlc_511 from '@/components/rlc_511.vue'
import zoom_e1 from '@/components/zoom_e1.vue'
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      argus_go: { // name of our custom icon
        component: argus_go_icon, // our custom component
      },
      r511w: { // name of our custom icon
        component: r511w, // our custom component
      },
      reolink_go: { // name of our custom icon
        component: reolink_go, // our custom component
      },
      rlc_511: { // name of our custom icon
        component: rlc_511, // our custom component
      },
      zoom_e1: { // name of our custom icon
        component: zoom_e1, // our custom component
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#0090a4',
        accent: '#FF7A00',
        error: '#B71C1C',
        success: '#169216',
        warning: '#FFCB1F',

        primary_blue: '#003F51',
        primary_red: '#B71C1C',
        oc_blue: '#42A5F5',
        oc_green: '#388E3C',
        oc_orange: '#F57C00',
        oc_yellow: '#FBC02D',
        oc_purple: '#ae4773',
        oc_indigo: '#093D72',
        oc_mint: '#00C7BE',
        oc_dark_grey: '#607D8B',
        oc_pink: '#F06292',
        oc_brown: '#8D6E63',
        oc_red: '#EF5350',
        oc_grey: '#BDBDBD',
        oc_white: '#EFEFEF',
        

        // oc_blue_dark: '#1E88E5',
        // oc_green_dark: '#00897B',
        // oc_orange_dark: '#00897B',
        // oc_yellow_dark: '#FDD835',
        // oc_purple_dark: '#8E24AA',
        // oc_indigo_dark: '#3949AB',
        // oc_pink_dark: '#F06292',
        // oc_brown_dark: '#8D6E63',
        // oc_red_dark: '#EF5350',
        // oc_grey_dark: '#BDBDBD'
      },
      dark: {
        primary: '#0090a4',
        accent: '#FF7A00',
        error: '#B71C1C',
        success: '#169216',
        warning: '#FFCB1F',

        primary_blue: '#0090a4',
        primary_red: '#B71C1C',
        oc_blue: '#42A5F5',
        oc_green: '#388E3C',
        oc_orange: '#F57C00',
        oc_yellow: '#FBC02D',
        oc_purple: '#ae4773',
        oc_indigo: '#093D72',
        oc_mint: '#00C7BE',
        oc_dark_grey: '#607D8B',
        oc_pink: '#F06292',
        oc_brown: '#8D6E63',
        oc_red: '#EF5350',
        oc_grey: '#BDBDBD',
        oc_white: '#EFEFEF',
      },
    },
  },
});
