export const customer_by_pk = /* GraphQL */ `
  query customer_by_pk($id: String!) {
    customer_by_pk(id: $id) {
      first_name
      last_name
      updated_at
      unit_system
      type
      referred_by
      status
      stripe_id

      social_youtube
      social_x
      social_other
      social_linkedin
      social_instagram
      social_facebook

      preferred_name
      phone_work
      phone_home
      phone_fax
      phone_cell
      initial_login_web
      initial_login_mobile
      id
      created_at
      company_website
      company_occupation
      company_name
      company_logo
      company_brand_image
    }
  }
`;

export const address_by_pk = /* GraphQL */ `
  query address_by_pk($customer_id: String!, $type: String!) {
    address_by_pk(customer_id: $customer_id, type: $type) {
      updated_at
      type
      province
      postal_code
      line_2
      line_1
      customer_id
      created_at
      country
      city
    }
  }
`;

export const notification_preference_by_pk = /* GraphQL */ `
  query notification_preference_by_pk($customer_id: String!) {
    notification_preference_by_pk(customer_id: $customer_id) {
      warning_sms_frequency
      warning_email_frequency
      #warning_call_frequency
      info_sms_frequency
      info_email_frequency
      # info_call_frequency
      alert_sms_frequency
      alert_email_frequency
      # alert_call_frequency
    }
  }
`;

export const share = /* GraphQL */ `
  query share($where: share_bool_exp) {
    share(where: $where) {
      permission
      owner_email
      collaborator_email
      customer_id_owner
      customer_id_collaborator

      approved
    }
  }
`;

export const site = /* GraphQL */ `
  query site($where: site_bool_exp) {
    site(where: $where, order_by: { name: asc }) {
      weight_scale
      updated_at
      timezone
      rfid_reader
      public_ip
      perimeter_long
      perimeter_lat
      operation_type
      name
      map_zoom_level
      isp_download_mbps
      isp_upload_mbps
      isp_datacap_gb
      isp_billing_cycle_day
      internet_provider
      id
      customer_id
      created_at
      coordinates_long
      coordinates_lat
      community_pasture_used
      assigned_id
      area
    }
  }
`;

export const camera = /* GraphQL */ `
  query camera($where: camera_bool_exp, $order_by: [camera_order_by!]) {
    camera(where: $where, order_by: $order_by) {
      blurriness_label
      updated_at
      status
      shipped
      site_id
      name
      model
      map_icon_color
      manufacturer
      latest_upload_type
      latest_upload_source
      latest_upload_bitrate_kbps
      latest_upload_corrupted
      latest_upload_fps
      latest_upload
      latest_upload_resolution
      last_video_upload
      last_status_update
      inventory_number
      id
      footage_thumbnail_url
      customer_id
      created_at
      coordinates_long
      coordinates_lat
      broken_reason
      broken
      predator_notifications_enabled
      estrus_notifications_enabled
      calving_notifications_enabled
    }
  }
`;

export const wifi = /* GraphQL */ `
  query wifi($where: wifi_bool_exp) {
    wifi(where: $where) {
      updated_at
      status
      site_id
      shipped
      model
      map_icon_color
      manufacturer
      inventory_number
      id
      device_type
      customer_id
      created_at
      coordinates_lat
      coordinates_long
    }
  }
`;

export const event = /* GraphQL */ `
  query event($where: event_bool_exp) {
    event(where: $where) {
      zone_id
      updated_at
      type
      title
      site_id
      id
      herd_id
      description
      datetime_start
      datetime_end
      customer_id
      created_at
      color
      animal_id
    }
  }
`;
export const user_notification_count_by_day = /* GraphQL */ `
  query user_notification_count_by_day(
    $where: user_notification_count_by_day_bool_exp
  ) {
    user_notification_count_by_day(where: $where) {
      notification_date
      notification_count
    }
  }
`;

export const herd = /* GraphQL */ `
  query herd($where: herd_bool_exp) {
    herd(where: $where, order_by: { name: asc }) {
      updated_at
      tag_color
      name
      id
      customer_id
      created_date
      created_at
    }
  }
`;

export const zone = /* GraphQL */ `
  query zone($where: zone_bool_exp) {
    zone(where: $where, order_by: { name: asc }) {
      updated_at
      type
      site_id
      perimeter_lat
      perimeter_meters
      perimeter_long
      name
      id
      customer_id
      created_at
      color
      area_square_meters
    }
  }
`;

// ANIMALS

export const list_animals = /* GraphQL */ `
  query list_animals(
    $where: list_animals_bool_exp
    $order_by: [list_animals_order_by!]
    $limit: Int
    $offset: Int
  ) {
    list_animals(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      zone_id
      vigor
      updated_at
      tag_id
      tag_label
      tag_color
      surrogate_dam_id
      status
      species
      site_id
      site_dod
      site_doa
      sire_tag_label
      sire_tag_id
      sire_tag_color
      sire_image_timestamp
      sex
      sire_id
      sire_image_id
      rfid_current
      purpose
      production_history_type
      production_history_start_datetime
      phenotype_udder
      phenotype_teat
      phenotype_semen
      phenotype_scrotum
      phenotype_leg
      phenotype_id
      phenotype_hoof
      phenotype_examiner
      phenotype_date
      phenotype_claw
      phenotype_bcs
      pedigree {
        updated_at
        tattoo
        registration_number
        registration_doc
        registration_date
        registration_association
        registered_name
        deleted_at
        customer_id
        created_at
        animal_id
      }
      ownership_status
      nurse_info
      notes(order_by: { datetime: asc }) {
        id
        comment
        datetime
      }
      name
      measurement_weight_yearling_id
      measurement_weight_yearling
      measurement_weight_weaning_id
      measurement_weight_weaning
      measurement_weight_mature_id
      measurement_weight_mature
      measurement_weight_birth_id
      measurement_weight_birth
      measurement_sperm_motility_id
      measurement_sperm_motility
      measurement_sperm_morphology_id
      measurement_sperm_morphology
      measurement_scrotum_circumference_id
      measurement_scrotum_circumference
      measurement_pelvic_width_id
      measurement_pelvic_width
      measurement_pelvic_height_id
      measurement_pelvic_height
      measurement_height_id
      measurement_height
      last_seen
      keywords
      image_timestamp
      image_id
      id
      herd_name
      herd_id
      docility
      dob
      disposal_code
      dam_tag_label
      dam_tag_id
      dam_tag_color
      dam_status
      dam_image_timestamp
      dam_image_id
      customer_id
      dam_id
      created_at
      conception_type
      color
      cod
      classification
      breeding_history_type
      breeding_history_start_date
      breeding_history_pregnant
      breeding_history_id
      breeding_history_end_date
      breed
      brand
      birth_ease
    }
  }
`;
export const list_animals_aggregate = /* GraphQL */ `
  query list_animals_aggregate($where: list_animals_bool_exp) {
    list_animals_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
      nodes {
        id
        tag_label
        tag_id
        tag_color
      }
    }
  }
`;
export const animals_by_herd_for_treatments = /* GraphQL */ `
  query animal($herd_id: String_comparison_exp) {
    animal(where: { herd_id: $herd_id }) {
      id
    }
  }
`;

export const list_selection_animals = /* GraphQL */ `
  query list_animals(
    $where: list_animals_bool_exp
    $order_by: [list_animals_order_by!]
  ) {
    list_animals(where: $where, order_by: $order_by, limit: 10) {
      tag_label
      tag_color
      id
      name
      rfid_current
      image_id
      image_timestamp
      customer_id
    }
  }
`;

export const animals_by_classification = /* GraphQL */ `
  query animals_by_classification($where: animals_by_classification_bool_exp) {
    animals_by_classification(
      where: $where
      order_by: { classification: asc }
    ) {
      count
      classification
    }
  }
`;

export const cows_by_breeding_type = /* GraphQL */ `
  query cows_by_breeding_type($where: cows_by_breeding_type_bool_exp) {
    cows_by_breeding_type(where: $where, order_by: { type: asc }) {
      animal_count
      type
    }
  }
`;

export const cows_by_dam_status = /* GraphQL */ `
  query cows_by_dam_status($where: cows_by_dam_status_bool_exp) {
    cows_by_dam_status(where: $where, order_by: { dam_status: asc }) {
      dam_status
      count
    }
  }
`;

export const animals_by_cod = /* GraphQL */ `
  query animals_by_cod($where: animals_by_cod_bool_exp) {
    animals_by_cod(where: $where, order_by: { cod: asc }) {
      status
      count
      cod
      classification
    }
  }
`;

export const calves_by_birth_ease = /* GraphQL */ `
  query calves_by_birth_ease($where: calves_by_birth_ease_bool_exp) {
    calves_by_birth_ease(where: $where, order_by: { birth_ease: asc }) {
      count
      birth_ease
    }
  }
`;

export const calves_by_sex = /* GraphQL */ `
  query calves_by_sex($where: calves_by_sex_bool_exp) {
    calves_by_sex(where: $where, order_by: { sex: asc }) {
      count
      sex
    }
  }
`;

export const bulls_by_scrotum_circumference = /* GraphQL */ `
  query bulls_by_scrotum_circumference(
    $where: bulls_by_scrotum_circumference_bool_exp
  ) {
    bulls_by_scrotum_circumference(
      where: $where
      order_by: { measurement_range: asc }
    ) {
      measurement_range
      animal_count
    }
  }
`;

export const bulls_by_sperm_morphology = /* GraphQL */ `
  query bulls_by_sperm_morphology($where: bulls_by_sperm_morphology_bool_exp) {
    bulls_by_sperm_morphology(where: $where) {
      measurement_range
      animal_count
    }
  }
`;

export const bulls_by_sperm_motility = /* GraphQL */ `
  query bulls_by_sperm_motility($where: bulls_by_sperm_motility_bool_exp) {
    bulls_by_sperm_motility(where: $where) {
      measurement_range
      animal_count
    }
  }
`;

// TREATMENTS
export const treatment = /* GraphQL */ `
  query treatment(
    $where: treatment_bool_exp
    $order_by: [treatment_order_by!]
    $limit: Int
    $offset: Int
  ) {
    treatment(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      date
      withdraw_date
      vet_name
      updated_at
      type
      reason
      keywords
      id
      customer_id
      created_at
      booster_date
      drugs(order_by: { name: asc }) {
        id
        name
        dose
      }
      user_notes(order_by: { datetime: desc }) {
        id
        comment
        datetime
      }
    }
  }
`;
export const treatment_aggregate = /* GraphQL */ `
  query treatment_aggregate($where: treatment_bool_exp) {
    treatment_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

export const list_treatments_by_drug_name = /* GraphQL */ `
  query list_treatments_by_drug_name(
    $where: list_treatments_by_drug_name_bool_exp
    $order_by: [list_treatments_by_drug_name_order_by!]
    $limit: Int
    $offset: Int
  ) {
    list_treatments_by_drug_name(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      date
      withdraw_date
      vet_name
      updated_at
      type
      reason
      keywords
      id
      customer_id
      created_at
      booster_date
    }
  }
`;
export const list_treatments_by_drug_name_aggregate = /* GraphQL */ `
  query list_treatments_by_drug_name_aggregate(
    $where: list_treatments_by_drug_name_bool_exp
  ) {
    list_treatments_by_drug_name_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

export const treatments_by_last_six_months = /* GraphQL */ `
  query treatments_by_last_six_months(
    $where: treatments_by_last_six_months_bool_exp
  ) {
    treatments_by_last_six_months(
      where: $where
      order_by: { year_month: asc }
    ) {
      year_month
      treatment_count
    }
  }
`;

export const treatments_by_reason = /* GraphQL */ `
  query treatments_by_reason($where: treatments_by_reason_bool_exp) {
    treatments_by_reason(where: $where) {
      reason
      treatment_count
    }
  }
`;

export const treatments_by_type = /* GraphQL */ `
  query treatments_by_type($where: treatments_by_type_bool_exp) {
    treatments_by_type(where: $where) {
      type
      treatment_count
    }
  }
`;

export const treatments_by_drug_name = /* GraphQL */ `
  query treatments_by_drug_name($where: treatments_by_drug_name_bool_exp) {
    treatments_by_drug_name(where: $where) {
      drug_name
      treatment_count
    }
  }
`;

// MEASUREMENTS

export const animals_by_weight_birth = /* GraphQL */ `
  query animals_by_weight_birth($where: animals_by_weight_birth_bool_exp) {
    animals_by_weight_birth(where: $where) {
      weight_range
      animal_count
    }
  }
`;
export const animals_by_weight_weaning = /* GraphQL */ `
  query animals_by_weight_weaning($where: animals_by_weight_weaning_bool_exp) {
    animals_by_weight_weaning(where: $where) {
      weight_range
      animal_count
    }
  }
`;
export const animals_by_weight_yearling = /* GraphQL */ `
  query animals_by_weight_yearling(
    $where: animals_by_weight_yearling_bool_exp
  ) {
    animals_by_weight_yearling(where: $where) {
      weight_range
      animal_count
    }
  }
`;
export const animals_by_weight_mature = /* GraphQL */ `
  query animals_by_weight_mature($where: animals_by_weight_mature_bool_exp) {
    animals_by_weight_mature(where: $where) {
      weight_range
      animal_count
    }
  }
`;

// PHENOTYPES

export const animals_by_phenotype_hoof = /* GraphQL */ `
  query animals_by_phenotype_hoof($where: animals_by_phenotype_hoof_bool_exp) {
    animals_by_phenotype_hoof(where: $where) {
      hoof_phenotype
      count
    }
  }
`;
export const animals_by_phenotype_claw = /* GraphQL */ `
  query animals_by_phenotype_claw($where: animals_by_phenotype_claw_bool_exp) {
    animals_by_phenotype_claw(where: $where) {
      claw_phenotype
      count
    }
  }
`;
export const animals_by_phenotype_udder = /* GraphQL */ `
  query animals_by_phenotype_udder(
    $where: animals_by_phenotype_udder_bool_exp
  ) {
    animals_by_phenotype_udder(where: $where) {
      udder_phenotype
      count
    }
  }
`;
export const animals_by_phenotype_teat = /* GraphQL */ `
  query animals_by_phenotype_teat($where: animals_by_phenotype_teat_bool_exp) {
    animals_by_phenotype_teat(where: $where) {
      teat_phenotype
      count
    }
  }
`;

// ANIMAL IDENTIFICATIONS
export const animal_image = /* GraphQL */ `
  query animal_image(
    $where: animal_image_bool_exp
    $order_by: [animal_image_order_by!]
  ) {
    animal_image(where: $where, limit: 50, order_by: $order_by) {
      uploaded_at
      updated_at
      tracking_id
      source
      reviewed_by
      review_status
      review_datetime
      media_timestamp
      id
      frame_num
      detected_tag_label
      detected_tag_color
      deleted_at
      customer_id
      created_at
      comment
      camera_id
      bbox_y
      bbox_x
      bbox_w
      bbox_h
      animal_id
    }
  }
`;

//NOTIFICATIONS
export const notification = /* GraphQL */ `
  query notification(
    $where: notification_bool_exp
    $order_by: [notification_order_by!]
    $limit: Int
    $offset: Int
  ) {
    notification(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      bbox
      contraction_conf_2
      contraction_conf
      contraction_label
      contraction_label_2
      tag_1_id_conf
      tag_1_id_label
      tag_2_id_conf
      tag_2_id_label
      tail_bbox
      tracking_id
      value
      updated_at
      type
      twilio_sid
      title
      sent_datetime_twilio
      sent_datetime_sendgrid
      sendgrid_id
      read
      level
      media_url
      id
      description
      datetime
      created_at
      customer_id
      camera_id
      animal_id
      camera {
        name
      }
    }
  }
`;
export const notification_aggregate = /* GraphQL */ `
  query notification_aggregate($where: notification_bool_exp) {
    notification_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

//VIDEOS
export const inference_media_video = /* GraphQL */ `
  query inference_media(
    $where: inference_media_bool_exp
    $order_by: [inference_media_order_by!]
    $limit: Int
    $offset: Int
  ) {
    inference_media(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      width
      uploaded_at
      ratio
      num_frames_inferred
      num_frames
      media_type
      media_timestamp_to_inference
      media_timestamp
      keypoint_type
      json_type
      inference_to_upload
      inference_start
      height
      inference_end
      fps
      customer_id
      camera_id
    }
  }
`;

//NOTES
export const user_note = /* GraphQL */ `
  query user_note(
    $where: user_note_bool_exp
    $order_by: [user_note_order_by!]
    $limit: Int
    $offset: Int
  ) {
    user_note(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      updated_at
      treatment_id
      site_id
      phenotype_id
      measurement_id
      id
      herd_id
      datetime
      customer_id
      created_at
      comment
      carcass_id
      author
      animal_id
    }
  }
`;

export const user_note_aggregate = /* GraphQL */ `
  query user_note_aggregate($where: user_note_bool_exp) {
    user_note_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

export const event_by_pk = /* GraphQL */ `
  query event_by_pk($id: String!) {
    event_by_pk(id: $id) {
      zone_id
      updated_at
      type
      title
      site_id
      id
      herd_id
      description
      datetime_start
      datetime_end
      customer_id
      created_at
      color
      animal_id
    }
  }
`;

export const inference_media = /* GraphQL */ `
  query inference_media(
    $where: inference_media_bool_exp
    $limit: Int
    $offset: Int
    $where_pgie: inference_pgie_bool_exp
  ) {
    inference_media(
      where: $where
      order_by: { media_timestamp: desc }
      limit: $limit
      offset: $offset
    ) {
      media_type
      media_timestamp
      customer_id
      camera_id
      pgies(where: $where_pgie) {
        bbox
        tracking_id
        body_parts {
          foot_1_bbox
          foot_1_conf
          foot_1_label
          foot_2_bbox
          foot_2_conf
          foot_2_label
          foot_3_bbox
          foot_3_conf
          foot_3_label
          foot_4_bbox
          foot_4_conf
          foot_4_label
          head_bbox
          head_conf
          head_label
          joint_1_bbox
          joint_1_conf
          joint_1_label
          joint_2_bbox
          joint_2_conf
          joint_2_label
          joint_3_bbox
          joint_3_conf
          joint_3_label
          joint_4_bbox
          joint_4_conf
          joint_4_label
          tag_1_bbox
          tag_1_colour_conf
          tag_1_colour_id
          tag_1_colour_label
          tag_1_conf
          tag_1_id_conf
          tag_1_id_label
          tag_1_label
          tag_2_bbox
          tag_2_colour_conf
          tag_2_colour_id
          tag_2_colour_label
          tag_2_conf
          tag_2_id_conf
          tag_2_id_label
          tag_2_label
          tail_bbox
          tail_conf
          tail_label
        }
        class_label
        class_conf
        keypoints {
          keypoints
        }
        classifications {
          species_label
          species_conf
          contraction_conf_2
          contraction_conf
          contraction_label
          contraction_label_2
          age_label
          age_conf
          activity_label
          activity_conf
          breed_label
          breed_conf
        }
      }
    }
  }
`;

export const analytics_calving = /* GraphQL */ `
  query analytics_calving(
    $where: analytics_calving_bool_exp
    $limit: Int
    $offset: Int
  ) {
    analytics_calving(
      where: $where
      order_by: { media_timestamp: desc }
      limit: $limit
      offset: $offset
    ) {
      valid_calving_event
      uploaded_at
      tracking_id
      total_processing_time
      tail_ratio
      tail_conf
      tail_bbox
      tail_area
      tag_2_label
      tag_2_id_label
      tag_2_id_conf
      tag_2_conf
      tag_2_colour_label
      tag_2_colour_conf
      tag_2_bbox
      tag_1_label
      species_conf
      species_label
      tag_1_bbox
      tag_1_id_label
      tag_1_id_conf
      tag_1_conf
      tag_1_colour_label
      tag_1_colour_conf
      s3_uri
      recent_sent_check
      notification_timestamp
      media_timestamp
      history_check
      group_filter
      gpt_check
      frame_num
      customer_id
      contraction_label_2
      contraction_label
      contraction_conf_2
      contraction_conf
      camera_id
      calving_label
      calving_description_tsvector
      calving_description
      calving_conf
      bbox_ratio
      bbox_area
      bbox
    }
  }
`;

export const analytics_estrus = /* GraphQL */ `
  query analytics_estrus(
    $where: analytics_estrus_bool_exp
    $limit: Int
    $offset: Int
  ) {
    analytics_estrus(
      where: $where
      order_by: { media_timestamp: desc }
      limit: $limit
      offset: $offset
    ) {
      uploaded_at
      tracking_id
      s3_uri
      recent_sent_check
      oo_tracking_id
      oo_bbox_y
      oo_bbox_x
      oo_bbox_w
      media_type
      media_timestamp
      oo_bbox_h
      gpt_description
      gpt_check
      frame_num
      customer_id
      conf_check
      class_conf
      class_label
      camera_id
      bbox_y
      bbox_x
      bbox_w
      bbox_ratio
      bbox_h
      bbox_area
    }
  }
`;

// ANALYTICS

export const media_pgie_count_by_camera = /* GraphQL */ `
  query media_pgie_count_by_camera(
    $args: media_pgie_count_by_camera_arguments!
  ) {
    media_pgie_count_by_camera(args: $args) {
      camera_id
      counts
    }
  }
`;

export const media_pgie_count_by_species = /* GraphQL */ `
  query media_pgie_count_by_species(
    $args: media_pgie_count_by_species_arguments!
  ) {
    media_pgie_count_by_species(args: $args) {
      species_label
      counts
    }
  }
`;

export const media_unique_tag_count_by_camera = /* GraphQL */ `
  query media_unique_tag_count_by_camera(
    $args: media_unique_tag_count_by_camera_arguments!
  ) {
    media_unique_tag_count_by_camera(args: $args) {
      camera_id
      counts
    }
  }
`;

export const media_unique_tag_count_by_species = /* GraphQL */ `
  query media_unique_tag_count_by_species(
    $args: media_unique_tag_count_by_species_arguments!
  ) {
    media_unique_tag_count_by_species(args: $args) {
      species_label
      counts
    }
  }
`;

export const media_contraction_count_by_camera = /* GraphQL */ `
  query media_contraction_count_by_camera(
    $args: media_contraction_count_by_camera_arguments!
  ) {
    media_contraction_count_by_camera(args: $args) {
      camera_id
      counts
    }
  }
`;

export const media_contraction_count_by_species = /* GraphQL */ `
  query media_contraction_count_by_species(
    $args: media_contraction_count_by_species_arguments!
  ) {
    media_contraction_count_by_species(args: $args) {
      species_label
      counts
    }
  }
`;

export const media_predator_count_by_camera = /* GraphQL */ `
  query media_predator_count_by_camera(
    $args: media_predator_count_by_camera_arguments!
  ) {
    media_predator_count_by_camera(args: $args) {
      camera_id
      counts
    }
  }
`;

export const media_predator_count_by_species = /* GraphQL */ `
  query media_predator_count_by_species(
    $args: media_predator_count_by_species_arguments!
  ) {
    media_predator_count_by_species(args: $args) {
      species_label
      counts
    }
  }
`;

/* SUMMARY */
export const summary_media_1day_user = /* GraphQL */ `
  query summary_media_1day_user(
    $limit: Int
    $where: summary_media_1day_user_bool_exp
  ) {
    summary_media_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;
export const summary_media_1hour_user = /* GraphQL */ `
  query summary_media_1hour_user(
    $limit: Int
    $where: summary_media_1hour_user_bool_exp
  ) {
    summary_media_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;
export const summary_media_5min_user = /* GraphQL */ `
  query summary_media_5min_user(
    $limit: Int
    $where: summary_media_5min_user_bool_exp
  ) {
    summary_media_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;

export const summary_media_1day_camera = /* GraphQL */ `
  query summary_media_1day_camera(
    $limit: Int
    $where: summary_media_1day_camera_bool_exp
  ) {
    summary_media_1day_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;
export const summary_media_1hour_camera = /* GraphQL */ `
  query summary_media_1hour_camera(
    $limit: Int
    $where: summary_media_1hour_camera_bool_exp
  ) {
    summary_media_1hour_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;
export const summary_media_5min_camera = /* GraphQL */ `
  query summary_media_5min_camera(
    $limit: Int
    $where: summary_media_5min_camera_bool_exp
  ) {
    summary_media_5min_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      total_time_to_upload_stddev
      media_type
      media_timestamp
      total_time_to_upload_mean
      num_frames_inferred
      num_frames
      media_count
    }
  }
`;

export const summary_pgie_1day_user = /* GraphQL */ `
  query summary_pgie_1day_user(
    $limit: Int
    $where: summary_pgie_1day_user_bool_exp
  ) {
    summary_pgie_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;
export const summary_pgie_1hour_user = /* GraphQL */ `
  query summary_pgie_1hour_user(
    $limit: Int
    $where: summary_pgie_1hour_user_bool_exp
  ) {
    summary_pgie_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;
export const summary_pgie_5min_user = /* GraphQL */ `
  query summary_pgie_5min_user(
    $limit: Int
    $where: summary_pgie_5min_user_bool_exp
  ) {
    summary_pgie_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
    }
  }
`;

export const summary_pgie_1day_camera = /* GraphQL */ `
  query summary_pgie_1day_camera(
    $limit: Int
    $where: summary_pgie_1day_camera_bool_exp
  ) {
    summary_pgie_1day_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
      camera_id
    }
  }
`;
export const summary_pgie_1hour_camera = /* GraphQL */ `
  query summary_pgie_1hour_camera(
    $limit: Int
    $where: summary_pgie_1hour_camera_bool_exp
  ) {
    summary_pgie_1hour_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
      camera_id
    }
  }
`;
export const summary_pgie_5min_camera = /* GraphQL */ `
  query summary_pgie_5min_camera(
    $limit: Int
    $where: summary_pgie_5min_camera_bool_exp
  ) {
    summary_pgie_5min_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      vehicle_count
      vehicle_class_conf_stddev
      vehicle_class_conf_mean
      vehicle_bbox_ratio_mean
      vehicle_bbox_area_mean
      person_count
      person_class_conf_stddev
      person_class_conf_mean
      person_bbox_ratio_mean
      person_bbox_area_mean
      media_timestamp
      animal_count
      animal_class_conf_stddev
      animal_class_conf_mean
      animal_bbox_ratio_mean
      animal_bbox_area_mean
      camera_id
    }
  }
`;

export const summary_activity_1day_user = /* GraphQL */ `
  query summary_activity_1day_user(
    $limit: Int
    $where: summary_activity_1day_user_bool_exp
  ) {
    summary_activity_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      swimming_count
      walking_conf_mean
      swimming_conf_stddev
      swimming_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      running_count
      running_conf_stddev
      running_conf_mean
      playing_conf_stddev
      playing_count
      lying_down_conf_mean
      lying_down_conf_stddev
      lying_down_count
      media_timestamp
      playing_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      calf_count
      calf_conf_stddev
      calf_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
    }
  }
`;
export const summary_activity_1hour_user = /* GraphQL */ `
  query summary_activity_1hour_user(
    $limit: Int
    $where: summary_activity_1hour_user_bool_exp
  ) {
    summary_activity_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      swimming_count
      walking_conf_mean
      swimming_conf_stddev
      swimming_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      running_count
      running_conf_stddev
      running_conf_mean
      playing_conf_stddev
      playing_count
      lying_down_conf_mean
      lying_down_conf_stddev
      lying_down_count
      media_timestamp
      playing_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      calf_count
      calf_conf_stddev
      calf_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
    }
  }
`;
export const summary_activity_5min_user = /* GraphQL */ `
  query summary_activity_5min_user(
    $limit: Int
    $where: summary_activity_5min_user_bool_exp
  ) {
    summary_activity_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      walking_count
      walking_conf_stddev
      swimming_count
      walking_conf_mean
      swimming_conf_stddev
      swimming_conf_mean
      standing_walking_count
      standing_walking_conf_stddev
      standing_walking_conf_mean
      sleeping_count
      sleeping_conf_stddev
      sleeping_conf_mean
      running_count
      running_conf_stddev
      running_conf_mean
      playing_conf_stddev
      playing_count
      lying_down_conf_mean
      lying_down_conf_stddev
      lying_down_count
      media_timestamp
      playing_conf_mean
      digging_count
      digging_conf_stddev
      digging_conf_mean
      climbing_stairs_count
      climbing_stairs_conf_stddev
      climbing_stairs_conf_mean
      calf_count
      calf_conf_stddev
      calf_conf_mean
      adult_count
      adult_conf_stddev
      adult_conf_mean
    }
  }
`;

export const summary_calving_1day_user = /* GraphQL */ `
  query summary_calving_1day_user(
    $limit: Int
    $where: summary_calving_1day_user_bool_exp
  ) {
    summary_calving_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;
export const summary_calving_1hour_user = /* GraphQL */ `
  query summary_calving_1hour_user(
    $limit: Int
    $where: summary_calving_1hour_user_bool_exp
  ) {
    summary_calving_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;
export const summary_calving_5min_user = /* GraphQL */ `
  query summary_calving_5min_user(
    $limit: Int
    $where: summary_calving_5min_user_bool_exp
  ) {
    summary_calving_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up2_count
      tail_up2_conf_stddev
      tail_up2_conf_mean
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down2_count
      tail_down2_conf_stddev
      tail_down2_conf_mean
      suckling_count
      suckling_conf_stddev
      suckling_conf_mean
      not_suckling_count
      not_suckling_conf_stddev
      not_suckling_conf_mean
      not_delivering_count
      not_delivering_conf_stddev
      not_delivering_conf_mean
      not_calving_count
      not_calving_conf_stddev
      not_calving_conf_mean
      media_timestamp
      delivering_count
      delivering_conf_stddev
      delivering_conf_mean
      calving_count
      calving_conf_stddev
      calving_conf_mean
    }
  }
`;

export const summary_analytics_calving_1day_camera = /* GraphQL */ `
  query summary_analytics_calving_1day_camera(
    $limit: Int
    $where: summary_analytics_calving_1day_camera_bool_exp
  ) {
    summary_analytics_calving_1day_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      valid_calving_event
      tail_up_ratio_stddev
      tail_up_ratio_mean
      tail_up_ratio_2nd_stddev
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up_conf_2nd_stddev
      tail_up_2_ratio_stddev
      tail_up_2_ratio_mean
      tail_up_2_ratio_2nd_stddev
      tail_up_2_count
      tail_up_2_conf_stddev
      tail_up_2_conf_mean
      tail_up_2_conf_2nd_stddev
      tail_down_ratio_stddev
      tail_down_ratio_mean
      tail_down_ratio_2nd_stddev
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down_conf_2nd_stddev
      tail_down_2_ratio_stddev
      tail_down_2_ratio_mean
      tail_down_2_ratio_2nd_stddev
      tail_down_2_count
      tail_down_2_conf_stddev
      tail_down_2_conf_mean
      tail_down_2_conf_2nd_stddev
      media_timestamp
      event_count
      customer_id
      camera_id
    }
  }
`;
export const summary_analytics_calving_1hour_camera = /* GraphQL */ `
  query summary_analytics_calving_1hour_camera(
    $limit: Int
    $where: summary_analytics_calving_1hour_camera_bool_exp
  ) {
    summary_analytics_calving_1hour_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      valid_calving_event
      tail_up_ratio_stddev
      tail_up_ratio_mean
      tail_up_ratio_2nd_stddev
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up_conf_2nd_stddev
      tail_up_2_ratio_stddev
      tail_up_2_ratio_mean
      tail_up_2_ratio_2nd_stddev
      tail_up_2_count
      tail_up_2_conf_stddev
      tail_up_2_conf_mean
      tail_up_2_conf_2nd_stddev
      tail_down_ratio_stddev
      tail_down_ratio_mean
      tail_down_ratio_2nd_stddev
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down_conf_2nd_stddev
      tail_down_2_ratio_stddev
      tail_down_2_ratio_mean
      tail_down_2_ratio_2nd_stddev
      tail_down_2_count
      tail_down_2_conf_stddev
      tail_down_2_conf_mean
      tail_down_2_conf_2nd_stddev
      media_timestamp
      event_count
      customer_id
      camera_id
    }
  }
`;
export const summary_analytics_calving_5min_camera = /* GraphQL */ `
  query summary_analytics_calving_5min_camera(
    $limit: Int
    $where: summary_analytics_calving_5min_camera_bool_exp
  ) {
    summary_analytics_calving_5min_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      valid_calving_event
      tail_up_ratio_stddev
      tail_up_ratio_mean
      tail_up_ratio_2nd_stddev
      tail_up_count
      tail_up_conf_stddev
      tail_up_conf_mean
      tail_up_conf_2nd_stddev
      tail_up_2_ratio_stddev
      tail_up_2_ratio_mean
      tail_up_2_ratio_2nd_stddev
      tail_up_2_count
      tail_up_2_conf_stddev
      tail_up_2_conf_mean
      tail_up_2_conf_2nd_stddev
      tail_down_ratio_stddev
      tail_down_ratio_mean
      tail_down_ratio_2nd_stddev
      tail_down_count
      tail_down_conf_stddev
      tail_down_conf_mean
      tail_down_conf_2nd_stddev
      tail_down_2_ratio_stddev
      tail_down_2_ratio_mean
      tail_down_2_ratio_2nd_stddev
      tail_down_2_count
      tail_down_2_conf_stddev
      tail_down_2_conf_mean
      tail_down_2_conf_2nd_stddev
      media_timestamp
      event_count
      customer_id
      camera_id
    }
  }
`;

export const summary_estrus_1day_camera = /* GraphQL */ `
  query summary_estrus_1day_camera(
    $limit: Int
    $where: summary_estrus_1day_camera_bool_exp
  ) {
    summary_estrus_1day_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
      camera_id
    }
  }
`;
export const summary_estrus_1hour_camera = /* GraphQL */ `
  query summary_estrus_1hour_camera(
    $limit: Int
    $where: summary_estrus_1hour_camera_bool_exp
  ) {
    summary_estrus_1hour_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
      camera_id
    }
  }
`;
export const summary_estrus_5min_camera = /* GraphQL */ `
  query summary_estrus_5min_camera(
    $limit: Int
    $where: summary_estrus_5min_camera_bool_exp
  ) {
    summary_estrus_5min_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      resting_chin_count
      resting_chin_conf_stddev
      resting_chin_conf_mean
      not_resting_chin_count
      not_resting_chin_conf_stddev
      not_resting_chin_conf_mean
      not_mounting_count
      not_mounting_conf_stddev
      not_mounting_conf_mean
      not_in_heat_count
      not_in_heat_conf_stddev
      not_in_heat_conf_mean
      mounting_count
      mounting_conf_stddev
      mounting_conf_mean
      media_timestamp
      in_heat_count
      in_heat_conf_stddev
      in_heat_conf_mean
      camera_id
    }
  }
`;

export const summary_species_1day_user = /* GraphQL */ `
  query summary_species_1day_user(
    $limit: Int
    $where: summary_species_1day_user_bool_exp
  ) {
    summary_species_1day_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_1hour_user = /* GraphQL */ `
  query summary_species_1hour_user(
    $limit: Int
    $where: summary_species_1hour_user_bool_exp
  ) {
    summary_species_1hour_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_5min_user = /* GraphQL */ `
  query summary_species_5min_user(
    $limit: Int
    $where: summary_species_5min_user_bool_exp
  ) {
    summary_species_5min_user(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;

export const summary_species_1day_camera = /* GraphQL */ `
  query summary_species_1day_camera(
    $limit: Int
    $where: summary_species_1day_camera_bool_exp
  ) {
    summary_species_1day_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      camera_id
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_1hour_camera = /* GraphQL */ `
  query summary_species_1hour_camera(
    $limit: Int
    $where: summary_species_1hour_camera_bool_exp
  ) {
    summary_species_1hour_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      camera_id
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;
export const summary_species_5min_camera = /* GraphQL */ `
  query summary_species_5min_camera(
    $limit: Int
    $where: summary_species_5min_camera_bool_exp
  ) {
    summary_species_5min_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      camera_id
      sheep_count
      sheep_conf_stddev
      sheep_conf_mean
      pig_count
      pig_conf_stddev
      pig_conf_mean
      media_timestamp
      llama_count
      llama_conf_stddev
      llama_conf_mean
      horse_count
      horse_conf_stddev
      horse_conf_mean
      goat_count
      goat_conf_stddev
      goat_conf_mean
      elk_count
      elk_conf_stddev
      elk_conf_mean
      donkey_count
      donkey_conf_stddev
      donkey_conf_mean
      cow_count
      cow_conf_mean
      cow_conf_stddev
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
      bison_count
      bison_conf_stddev
      bison_conf_mean
    }
  }
`;

export const summary_predator_1day_camera = /* GraphQL */ `
  query summary_species_1day_camera(
    $limit: Int
    $where: summary_species_1day_camera_bool_exp
  ) {
    summary_species_1day_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      camera_id
      media_timestamp
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
    }
  }
`;
export const summary_predator_1hour_camera = /* GraphQL */ `
  query summary_species_1hour_camera(
    $limit: Int
    $where: summary_species_1hour_camera_bool_exp
  ) {
    summary_species_1hour_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      camera_id
      media_timestamp
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
    }
  }
`;
export const summary_predator_5min_camera = /* GraphQL */ `
  query summary_species_5min_camera(
    $limit: Int
    $where: summary_species_5min_camera_bool_exp
  ) {
    summary_species_5min_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      camera_id
      media_timestamp
      cat_count
      cat_conf_stddev
      cat_conf_mean
      canine_count
      canine_conf_stddev
      canine_conf_mean
    }
  }
`;

export const summary_bodyparts_1day_camera = /* GraphQL */ `
  query summary_bodyparts_1day_camera(
    $limit: Int
    $where: summary_bodyparts_1day_camera_bool_exp
  ) {
    summary_bodyparts_1day_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_ratio_mean
      tail_count
      tail_conf_stddev
      tail_conf_mean
      tail_conf_2nd_stddev
      tail_area_mean
      tag_ratio_mean
      tag_count
      tag_conf_stddev
      tag_conf_mean
      tag_conf_2nd_stddev
      media_timestamp
      tag_area_mean
      joint_ratio_mean
      joint_count
      joint_conf_stddev
      joint_conf_mean
      joint_conf_2nd_stddev
      joint_area_mean
      head_ratio_mean
      head_count
      head_conf_stddev
      head_conf_mean
      head_conf_2nd_stddev
      head_area_mean
      foot_ratio_mean
      foot_count
      foot_conf_stddev
      foot_conf_mean
      foot_conf_2nd_stddev
      foot_area_mean
      customer_id
      camera_id
    }
  }
`;
export const summary_bodyparts_1hour_camera = /* GraphQL */ `
  query summary_bodyparts_1hour_camera(
    $limit: Int
    $where: summary_bodyparts_1hour_camera_bool_exp
  ) {
    summary_bodyparts_1hour_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_ratio_mean
      tail_count
      tail_conf_stddev
      tail_conf_mean
      tail_conf_2nd_stddev
      tail_area_mean
      tag_ratio_mean
      tag_count
      tag_conf_stddev
      tag_conf_mean
      tag_conf_2nd_stddev
      media_timestamp
      tag_area_mean
      joint_ratio_mean
      joint_count
      joint_conf_stddev
      joint_conf_mean
      joint_conf_2nd_stddev
      joint_area_mean
      head_ratio_mean
      head_count
      head_conf_stddev
      head_conf_mean
      head_conf_2nd_stddev
      head_area_mean
      foot_ratio_mean
      foot_count
      foot_conf_stddev
      foot_conf_mean
      foot_conf_2nd_stddev
      foot_area_mean
      customer_id
      camera_id
    }
  }
`;
export const summary_bodyparts_5min_camera = /* GraphQL */ `
  query summary_bodyparts_5min_camera(
    $limit: Int
    $where: summary_bodyparts_5min_camera_bool_exp
  ) {
    summary_bodyparts_5min_camera(
      order_by: { media_timestamp: desc }
      limit: $limit
      where: $where
    ) {
      tail_ratio_mean
      tail_count
      tail_conf_stddev
      tail_conf_mean
      tail_conf_2nd_stddev
      tail_area_mean
      tag_ratio_mean
      tag_count
      tag_conf_stddev
      tag_conf_mean
      tag_conf_2nd_stddev
      media_timestamp
      tag_area_mean
      joint_ratio_mean
      joint_count
      joint_conf_stddev
      joint_conf_mean
      joint_conf_2nd_stddev
      joint_area_mean
      head_ratio_mean
      head_count
      head_conf_stddev
      head_conf_mean
      head_conf_2nd_stddev
      head_area_mean
      foot_ratio_mean
      foot_count
      foot_conf_stddev
      foot_conf_mean
      foot_conf_2nd_stddev
      foot_area_mean
      customer_id
      camera_id
    }
  }
`;

// DASHBOARDS STATS

export const summary_camera_media_upload_summary = /* GraphQL */ `
  query summary_camera_media_upload_summary(
    $where: summary_camera_media_upload_summary_bool_exp!
  ) {
    summary_camera_media_upload_summary(where: $where) {
      only_video_cameras
      only_image_cameras
      customer_id
      both_image_and_video_cameras
    }
  }
`;


export const summary_camera_online_counta_1d = /* GraphQL */ `
  query summary_camera_online_counta_1d(
    $where: summary_camera_online_counta_1d_bool_exp!
  ) {
    summary_camera_online_counta_1d(where: $where) {
      customer_id
      unique_camera_count
    }
  }
`;
export const summary_camera_online_counta_7d = /* GraphQL */ `
  query summary_camera_online_counta_7d(
    $where: summary_camera_online_counta_7d_bool_exp!
  ) {
    summary_camera_online_counta_7d(where: $where) {
      customer_id
      unique_camera_count
    }
  }
`;
export const summary_camera_online_counta_30d = /* GraphQL */ `
  query summary_camera_online_counta_1d(
    $where: summary_camera_online_counta_30d_bool_exp!
  ) {
    summary_camera_online_counta_30d(where: $where) {
      customer_id
      unique_camera_count
    }
  }
`;

