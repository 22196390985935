import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

// import VueToast from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-default.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBAWqigAHgLwMgexZJp2WCmCFK9H8OafI0',
    libraries: 'places, drawing', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

// Vue.use(VueToast);

export const eventBus = new Vue();

Vue.config.productionTip = true;


new Vue({
  vuetify,
  router,
  store,

  render: (h) => h(App),


  created() {

    // if (localStorage.getItem('version') !=  version["version"]) {
    //   localStorage.setItem('version', version["version"])
    //   this.$store.dispatch('logout')
    
    // } else {
      this.$store.dispatch('fetchUser');
    // }
    

  },


  // watch: {
  //   '$store.state.account.authorized': async function (n, o) {
  //     if (n, o) {
  //       //logic for user logging in
  //       await this.$api('user', 'signin') //example for logging in user to server
  //       if (this.$route.path !== '/' || !o)
  //         this.$router.push('/')
  //     } else {
  //       //logic for user logging out
  //       if (this.$route.path !== '/')
  //         this.$router.push('/')
  //     }
  //   },
  // },
  // attempt to login user from session




}).$mount('#app');


